var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { HEADER, METHOD } from '.';
export var EOCRRequestStatus;
(function (EOCRRequestStatus) {
    EOCRRequestStatus["INIT"] = "INIT";
    EOCRRequestStatus["STARTED"] = "STARTED";
    EOCRRequestStatus["DONE"] = "DONE";
    EOCRRequestStatus["FAILED"] = "FAILED";
    EOCRRequestStatus["DELETED"] = "DELETED";
})(EOCRRequestStatus || (EOCRRequestStatus = {}));
export var EOCRResultFormat;
(function (EOCRResultFormat) {
    EOCRResultFormat["TEXT"] = "text/plain";
    EOCRResultFormat["JSON"] = "application/json";
})(EOCRResultFormat || (EOCRResultFormat = {}));
export var DocumentEditionEventBase;
(function (DocumentEditionEventBase) {
    DocumentEditionEventBase["CONTENT_CREATED"] = "CONTENT_CREATED";
    DocumentEditionEventBase["CONTENT_DELETED"] = "CONTENT_DELETED";
    DocumentEditionEventBase["CONTENT_OCR_DELETED"] = "CONTENT_OCR_DELETED";
    DocumentEditionEventBase["CONTENT_OCR_REQUESTED"] = "CONTENT_OCR_REQUESTED";
    DocumentEditionEventBase["CONTENT_UPLOADED"] = "CONTENT_UPLOADED";
    DocumentEditionEventBase["DOCUMENT_CREATED"] = "DOCUMENT_CREATED";
    DocumentEditionEventBase["DOCUMENT_TREE_DELETED"] = "DOCUMENT_TREE_DELETED";
    DocumentEditionEventBase["DOCUMENT_VERSIONED"] = "DOCUMENT_VERSIONED";
})(DocumentEditionEventBase || (DocumentEditionEventBase = {}));
export var DocumentViewingEventBase;
(function (DocumentViewingEventBase) {
    DocumentViewingEventBase["CONTENT_OCR_VIEWED"] = "CONTENT_OCR_VIEWED";
    DocumentViewingEventBase["CONTENT_VIEWED"] = "CONTENT_VIEWED";
    DocumentViewingEventBase["CONTENTS_LISTED"] = "CONTENTS_LISTED";
    DocumentViewingEventBase["DOCUMENT_RETRIEVED"] = "DOCUMENT_RETRIEVED";
    DocumentViewingEventBase["DOCUMENT_VERSIONS_LISTED"] = "DOCUMENT_VERSIONS_LISTED";
})(DocumentViewingEventBase || (DocumentViewingEventBase = {}));
export const DocumentEventBase = Object.assign(Object.assign({}, DocumentEditionEventBase), DocumentViewingEventBase);
export var PermalinkEditionEvent;
(function (PermalinkEditionEvent) {
    PermalinkEditionEvent["CONTENT_PERMALINKS_REVOKED"] = "CONTENT_PERMALINKS_REVOKED";
    PermalinkEditionEvent["PERMALINK_CREATED"] = "PERMALINK_CREATED";
    PermalinkEditionEvent["PERMALINK_UPDATED"] = "PERMALINK_UPDATED";
    // EXPIRED_DOCUMENTS_PERMALINKS_REVOKED = "EXPIRED_DOCUMENTS_PERMALINKS_REVOKED",
    // DOCUMENT_PERMALINKS_REVOKED = 'DOCUMENT_PERMALINKS_REVOKED',
    // PERMALINK_REVOKED = 'PERMALINK_REVOKED',
    // PERMALINK_REVOKED_AFTER_EXPIRATION = 'PERMALINK_REVOKED_AFTER_EXPIRATION',
})(PermalinkEditionEvent || (PermalinkEditionEvent = {}));
export var PermalinkViewingEvent;
(function (PermalinkViewingEvent) {
    PermalinkViewingEvent["PERMALINK_NOTIFIED"] = "PERMALINK_NOTIFIED";
    PermalinkViewingEvent["PERMALINK_OPENED"] = "PERMALINK_OPENED";
    PermalinkViewingEvent["PERMALINK_RETRIEVED"] = "PERMALINK_RETRIEVED";
})(PermalinkViewingEvent || (PermalinkViewingEvent = {}));
export const PermalinkEvent = Object.assign(Object.assign({}, PermalinkEditionEvent), PermalinkViewingEvent);
export const DocumentEditionEvent = Object.assign(Object.assign({}, DocumentEditionEventBase), PermalinkEditionEvent);
export const DocumentViewingEvent = Object.assign(Object.assign({}, DocumentViewingEventBase), PermalinkViewingEvent);
export const DocumentEvent = Object.assign(Object.assign({}, DocumentEventBase), PermalinkEvent);
export var EChannel;
(function (EChannel) {
    EChannel["SG_MARKETS"] = "SG_MARKETS";
    EChannel["EMAIL"] = "EMAIL";
})(EChannel || (EChannel = {}));
export var ESubscriptionWorkspaceEvent;
(function (ESubscriptionWorkspaceEvent) {
    ESubscriptionWorkspaceEvent["WORKSPACE_UPDATED"] = "WORKSPACE_UPDATED";
    ESubscriptionWorkspaceEvent["WORKSPACE_DELETED"] = "WORKSPACE_DELETED";
    ESubscriptionWorkspaceEvent["MODEL_CREATED"] = "MODEL_CREATED";
    ESubscriptionWorkspaceEvent["MODEL_UPDATED"] = "MODEL_UPDATED";
    ESubscriptionWorkspaceEvent["MODEL_DELETED"] = "MODEL_DELETED";
})(ESubscriptionWorkspaceEvent || (ESubscriptionWorkspaceEvent = {}));
export var ESubscriptionModelEvent;
(function (ESubscriptionModelEvent) {
    ESubscriptionModelEvent["DOCUMENT_CREATED"] = "DOCUMENT_CREATED";
    ESubscriptionModelEvent["DOCUMENT_VERSIONED"] = "DOCUMENT_VERSIONED";
    ESubscriptionModelEvent["DOCUMENT_TREE_DELETED"] = "DOCUMENT_TREE_DELETED";
})(ESubscriptionModelEvent || (ESubscriptionModelEvent = {}));
export var ESubscriptionDocumentVersionEvent;
(function (ESubscriptionDocumentVersionEvent) {
    ESubscriptionDocumentVersionEvent["CONTENT_CREATED"] = "CONTENT_CREATED";
    ESubscriptionDocumentVersionEvent["DOCUMENT_VERSIONED"] = "DOCUMENT_VERSIONED";
    ESubscriptionDocumentVersionEvent["DOCUMENT_TREE_DELETED"] = "DOCUMENT_TREE_DELETED";
})(ESubscriptionDocumentVersionEvent || (ESubscriptionDocumentVersionEvent = {}));
export var ESubscriptionFrequency;
(function (ESubscriptionFrequency) {
    ESubscriptionFrequency["LIVE"] = "LIVE";
    // END_OF_DAY = 'END_OF_DAY'
})(ESubscriptionFrequency || (ESubscriptionFrequency = {}));
export var ESubscriptionType;
(function (ESubscriptionType) {
    ESubscriptionType["WORKSPACE"] = "WORKSPACE";
    ESubscriptionType["MODEL"] = "MODEL";
    ESubscriptionType["DOCUMENT"] = "DOCUMENT";
    ESubscriptionType["RIGHT"] = "RIGHT";
    ESubscriptionType["PERMALINK"] = "PERMALINK";
})(ESubscriptionType || (ESubscriptionType = {}));
export var EIcMetadataXSource;
(function (EIcMetadataXSource) {
    EIcMetadataXSource["IC_CLIENT"] = "IC_CLIENT";
    EIcMetadataXSource["IC_LEGAL_ENTITY"] = "IC_LEGAL_ENTITY";
    EIcMetadataXSource["IC_ELEMENTARY_CLIENT"] = "IC_ELEMENTARY_CLIENT";
})(EIcMetadataXSource || (EIcMetadataXSource = {}));
export var EJedeaiMetadataXSource;
(function (EJedeaiMetadataXSource) {
    EJedeaiMetadataXSource["SPARK_MONTAGE"] = "SPARK_MONTAGE";
    EJedeaiMetadataXSource["SPARK_MONTAGE_SEARCH"] = "SPARK_MONTAGE_SEARCH";
    EJedeaiMetadataXSource["SPARK_PRODUCT_ISSUE"] = "SPARK_PRODUCT_ISSUE";
    EJedeaiMetadataXSource["SPARK_PRODUCT_ISSUE_SEARCH"] = "SPARK_PRODUCT_ISSUE_SEARCH";
    EJedeaiMetadataXSource["ELIOT_BASKET"] = "ELIOT_BASKET";
    EJedeaiMetadataXSource["ELIOT_BASKET_SEARCH"] = "ELIOT_BASKET_SEARCH";
    EJedeaiMetadataXSource["ELIOT_FLOW"] = "ELIOT_FLOW";
    EJedeaiMetadataXSource["ELIOT_FLOW_SEARCH"] = "ELIOT_FLOW_SEARCH";
    EJedeaiMetadataXSource["X_ONE_DEAL"] = "X_ONE_DEAL";
    EJedeaiMetadataXSource["X_ONE_DEAL_SEARCH"] = "X_ONE_DEAL_SEARCH";
    EJedeaiMetadataXSource["GALAXY_PRODUCT"] = "GALAXY_PRODUCT";
    EJedeaiMetadataXSource["GALAXY_PRODUCT_SEARCH"] = "GALAXY_PRODUCT_SEARCH";
    EJedeaiMetadataXSource["MAESTRO_COUNTERPARTY"] = "MAESTRO_COUNTERPARTY";
    EJedeaiMetadataXSource["MAESTRO_COUNTERPARTY_SEARCH"] = "MAESTRO_COUNTERPARTY_SEARCH";
    EJedeaiMetadataXSource["MAESTRO_CARE_COUNTERPARTY"] = "MAESTRO_CARE_COUNTERPARTY";
    EJedeaiMetadataXSource["MAESTRO_CARE_COUNTERPARTY_SEARCH"] = "MAESTRO_CARE_COUNTERPARTY_SEARCH";
    EJedeaiMetadataXSource["MAESTRO_LEGAL_ENTITY"] = "MAESTRO_LEGAL_ENTITY";
    EJedeaiMetadataXSource["MAESTRO_LEGAL_ENTITY_SEARCH"] = "MAESTRO_LEGAL_ENTITY_SEARCH";
    EJedeaiMetadataXSource["TERMSHEET_CONTROL"] = "TERMSHEET_CONTROL";
    EJedeaiMetadataXSource["TERMSHEET_CONTROL_SEARCH"] = "TERMSHEET_CONTROL_SEARCH";
})(EJedeaiMetadataXSource || (EJedeaiMetadataXSource = {}));
export const IC_METADATA_X_SOURCE_LIST = Object.keys(EIcMetadataXSource);
export const JEDEAI_METADATA_X_SOURCE_LIST = Object.keys(EJedeaiMetadataXSource);
const throwIfAuthorizationHeaderIsFalsy = ({ authorizationHeader }) => {
    if (!authorizationHeader) {
        throw new Error('You must be authenticated to perform this action.');
    }
    return authorizationHeader;
};
const computeBasicResponse = (response) => {
    if (response.ok || response.status === 401) {
        return response.json();
    }
    else if (response.status === 500) {
        throw response.json().then((errorJson) => {
            return Object.assign(Object.assign({}, errorJson), { traceId: response.headers.get('trace-id') });
        });
    }
    else {
        throw response.json();
    }
};
const computeBasicTextResponse = (response) => {
    if (response.ok || response.status === 401) {
        return response.text();
    }
    else if (response.status === 500) {
        throw response.text().then((errorText) => {
            return { errorText, traceId: response.headers.get('trace-id') };
        });
    }
    else {
        throw response.text();
    }
};
const computeBasicResponseWithoutBody = (response) => {
    if (response.ok) {
        return response.bodyUsed ? response.json() : Promise.resolve();
    }
    if (response.status === 401) {
        return response.json();
    }
    else if (response.status === 500) {
        throw response.json().then((errorJson) => {
            return Object.assign(Object.assign({}, errorJson), { traceId: response.headers.get('trace-id') });
        });
    }
    else {
        throw response.json();
    }
};
const computeAsyncBasicResponse = (response) => __awaiter(void 0, void 0, void 0, function* () {
    if (response.ok || response.status === 401) {
        return response.json();
    }
    else if (response.status === 500) {
        throw yield response.json().then((errorJson) => {
            return Object.assign(Object.assign({}, errorJson), { traceId: response.headers.get('trace-id') });
        });
    }
    else {
        throw yield response.json();
    }
});
const computeDeleteResponse = (response) => {
    if (response.status === 204) {
        return Promise.resolve(true);
    }
    else if (response.status === 401) {
        return response.json();
    }
    else if (response.status === 500) {
        throw response.json().then((errorJson) => {
            return Object.assign(Object.assign({}, errorJson), { traceId: response.headers.get('trace-id') });
        });
    }
    else {
        throw response.json();
    }
};
const createQueryParameters = (params) => {
    const urlParams = new URLSearchParams();
    for (const key in params) {
        const value = params[key];
        if (value !== undefined) {
            switch (typeof value) {
                case 'boolean':
                    urlParams.set(key, value ? 'true' : 'false');
                    break;
                case 'number':
                    urlParams.set(key, `${value}`);
                    break;
                default: urlParams.set(key, value);
            }
        }
    }
    return urlParams.toString();
};
const fixedEncodeURIComponent = (filename) => {
    return encodeURIComponent(filename).replace(/[!'()*]/g, function (c) {
        return '%' + c.charCodeAt(0).toString(16);
    });
};
export const createDocument = ({ workspaceId, modelId, confidentialityLevel, destroyDate, contentsOutsideSGNetwork, metadata, authorizationHeader, baseUrl, component = HEADER.COMPONENT_WEB, }) => {
    authorizationHeader = throwIfAuthorizationHeaderIsFalsy({
        authorizationHeader
    });
    const queryParameters = createQueryParameters({ confidentialityLevel, destroyDate, contentsOutsideSGNetwork });
    return fetch(`${baseUrl}/workspaces/${workspaceId}/documents${queryParameters ? '?' + queryParameters : ''}`, {
        method: METHOD.POST,
        headers: {
            authorization: authorizationHeader,
            'Content-Type': HEADER.APPLICATION_JSON,
            accept: HEADER.APPLICATION_JSON,
            'sgm-docs-component': component
        },
        body: JSON.stringify({ modelId, metadata })
    }).then(computeBasicResponse);
};
export const copyDocument = ({ workspaceId, documentId, versionId, toWorkspaceId, toModelId, useTargetMetadata, metadataToAdd, confidentialityLevel, destroyDate, contentsOutsideSGNetwork, authorizationHeader, baseUrl, component = HEADER.COMPONENT_WEB }) => {
    authorizationHeader = throwIfAuthorizationHeaderIsFalsy({
        authorizationHeader
    });
    const queryParameters = createQueryParameters({ toWorkspaceId, toModelId, useTargetMetadata, confidentialityLevel, destroyDate, contentsOutsideSGNetwork });
    return fetch(`${baseUrl}/workspaces/${workspaceId}/documents/${documentId}/versions/${versionId}/copy${queryParameters ? '?' + queryParameters : ''}`, {
        method: METHOD.POST,
        headers: {
            authorization: authorizationHeader,
            'Content-Type': HEADER.APPLICATION_JSON,
            accept: HEADER.APPLICATION_JSON,
            'sgm-docs-component': component
        },
        body: JSON.stringify(metadataToAdd)
    }).then(computeBasicResponse);
};
export const deleteDocument = ({ workspaceId, documentId, authorizationHeader, baseUrl, component = HEADER.COMPONENT_WEB }) => {
    authorizationHeader = throwIfAuthorizationHeaderIsFalsy({
        authorizationHeader
    });
    return fetch(`${baseUrl}/workspaces/${workspaceId}/documents/${documentId}`, {
        method: METHOD.DELETE,
        headers: {
            authorization: authorizationHeader,
            'Content-Type': HEADER.APPLICATION_JSON,
            accept: HEADER.APPLICATION_JSON,
            'sgm-docs-component': component
        }
    }).then(computeDeleteResponse);
};
export const getDocumentVersions = ({ workspaceId, documentId, authorizationHeader, page = '1', size = '20', baseUrl }) => {
    authorizationHeader = throwIfAuthorizationHeaderIsFalsy({
        authorizationHeader
    });
    return fetch(`${baseUrl}/workspaces/${workspaceId}/documents/${documentId}/versions?page=${page}&size=${size}`, {
        headers: {
            authorization: authorizationHeader,
            'Content-Type': HEADER.APPLICATION_JSON,
            accept: HEADER.APPLICATION_JSON,
            'sgm-docs-component': HEADER.COMPONENT_WEB
        }
    }).then(computeBasicResponse);
};
export const getDocumentAudit = ({ workspaceId, documentId, eventTypes, search, authorizationHeader, page = '1', size = '20', sort = '-occurredOn', baseUrl }) => {
    authorizationHeader = throwIfAuthorizationHeaderIsFalsy({
        authorizationHeader
    });
    const queryParameters = createQueryParameters({ eventTypes: eventTypes.join(), search, page, size });
    return fetch(`${baseUrl}/workspaces/${workspaceId}/documents/${documentId}/audits?sort=${sort}${queryParameters ? '&' + queryParameters : ''}`, {
        headers: {
            authorization: authorizationHeader,
            'Content-Type': HEADER.APPLICATION_JSON,
            accept: HEADER.APPLICATION_JSON,
            'sgm-docs-component': HEADER.COMPONENT_WEB
        }
    }).then(computeBasicResponse);
};
export const getDocumentContents = ({ workspaceId, documentId, versionId = 'last', withOCRRequests = false, authorizationHeader, page = '1', size = '20', baseUrl, abortSignal, component = HEADER.COMPONENT_WEB }) => {
    authorizationHeader = throwIfAuthorizationHeaderIsFalsy({
        authorizationHeader
    });
    return fetch(`${baseUrl}/workspaces/${workspaceId}/documents/${documentId}/versions/${versionId}/contents?page=${page}&size=${size}&withOCRRequests=${withOCRRequests}`, {
        signal: abortSignal,
        headers: {
            authorization: authorizationHeader,
            'Content-Type': HEADER.APPLICATION_JSON,
            accept: HEADER.APPLICATION_JSON,
            'sgm-docs-component': component
        }
    }).then(computeBasicResponse);
};
export const getDocumentMetadata = ({ workspaceId, documentId, versionId = 'last', authorizationHeader, baseUrl }) => {
    authorizationHeader = throwIfAuthorizationHeaderIsFalsy({
        authorizationHeader
    });
    return fetch(`${baseUrl}/workspaces/${workspaceId}/documents/${documentId}/versions/${versionId}/metadata`, {
        headers: {
            authorization: authorizationHeader,
            'Content-Type': HEADER.APPLICATION_JSON,
            accept: HEADER.APPLICATION_JSON,
            'sgm-docs-component': HEADER.COMPONENT_WEB
        }
    }).then(computeBasicResponse);
};
export const getDocumentRight = ({ workspaceId, documentId, versionId = 'last', authorizationHeader, baseUrl }) => {
    authorizationHeader = throwIfAuthorizationHeaderIsFalsy({
        authorizationHeader
    });
    return fetch(`${baseUrl}/workspaces/${workspaceId}/documents/${documentId}/versions/${versionId}/rights`, {
        headers: {
            authorization: authorizationHeader,
            'Content-Type': HEADER.APPLICATION_JSON,
            accept: HEADER.APPLICATION_JSON,
            'sgm-docs-component': HEADER.COMPONENT_WEB
        }
    }).then(computeBasicResponse);
};
export const getModelRight = ({ workspaceId, modelId, authorizationHeader, baseUrl }) => {
    authorizationHeader = throwIfAuthorizationHeaderIsFalsy({
        authorizationHeader
    });
    return fetch(`${baseUrl}/workspaces/${workspaceId}/models/${modelId}/rights`, {
        headers: {
            authorization: authorizationHeader,
            'Content-Type': HEADER.APPLICATION_JSON,
            accept: HEADER.APPLICATION_JSON,
            'sgm-docs-component': HEADER.COMPONENT_WEB
        }
    }).then(computeBasicResponse);
};
export const getLinkedResources = ({ authorizationHeader, rightId, page = '1', size = '20', baseUrl }) => {
    authorizationHeader = throwIfAuthorizationHeaderIsFalsy({
        authorizationHeader
    });
    return fetch(`${baseUrl}/rights/${rightId}/resources?page=${page}&size=${size}`, {
        headers: {
            authorization: authorizationHeader,
            'Content-Type': HEADER.APPLICATION_JSON,
            accept: HEADER.APPLICATION_JSON,
            'sgm-docs-component': HEADER.COMPONENT_WEB
        }
    }).then((r) => __awaiter(void 0, void 0, void 0, function* () {
        if (r.ok) {
            return r.json();
        }
        else {
            throw yield r.json();
        }
    }));
};
export const getRight = ({ authorizationHeader, rightId, baseUrl }) => {
    authorizationHeader = throwIfAuthorizationHeaderIsFalsy({
        authorizationHeader
    });
    return fetch(`${baseUrl}/rights/${rightId}`, {
        headers: {
            authorization: authorizationHeader,
            'Content-Type': HEADER.APPLICATION_JSON,
            accept: HEADER.APPLICATION_JSON,
            'sgm-docs-component': HEADER.COMPONENT_WEB
        }
    }).then((r) => __awaiter(void 0, void 0, void 0, function* () {
        if (r.ok) {
            return r.json();
        }
        else {
            throw yield r.json();
        }
    }));
};
export const deleteRightById = ({ authorizationHeader, rightId, baseUrl }) => {
    authorizationHeader = throwIfAuthorizationHeaderIsFalsy({
        authorizationHeader
    });
    return fetch(`${baseUrl}/rights/${rightId}`, {
        method: METHOD.DELETE,
        headers: {
            authorization: authorizationHeader,
            'Content-Type': HEADER.APPLICATION_JSON,
            accept: HEADER.APPLICATION_JSON,
            'sgm-docs-component': HEADER.COMPONENT_WEB
        }
    }).then(computeDeleteResponse);
};
export const getSessionRight = ({ authorizationHeader, query, resourceType, page = '1', size = '20', baseUrl }) => {
    authorizationHeader = throwIfAuthorizationHeaderIsFalsy({
        authorizationHeader
    });
    const queryFilter = query ? `q=${query}&` : '';
    const resourceTypeFilter = resourceType ? `resourceType=${resourceType}&` : '';
    return fetch(`${baseUrl}/rights?${queryFilter}${resourceTypeFilter}page=${page}&size=${size}`, {
        headers: {
            authorization: authorizationHeader,
            'Content-Type': HEADER.APPLICATION_JSON,
            accept: HEADER.APPLICATION_JSON,
            'sgm-docs-component': HEADER.COMPONENT_WEB
        }
    }).then((r) => __awaiter(void 0, void 0, void 0, function* () {
        if (r.ok) {
            return r.json();
        }
        else {
            throw yield r.json();
        }
    }));
};
export const updateRight = ({ rightId, updatedRight, authorizationHeader, baseUrl }) => {
    authorizationHeader = throwIfAuthorizationHeaderIsFalsy({
        authorizationHeader
    });
    return fetch(`${baseUrl}/rights/${rightId}`, {
        headers: {
            authorization: authorizationHeader,
            accept: HEADER.APPLICATION_JSON,
            'content-type': HEADER.APPLICATION_JSON,
            'sgm-docs-component': HEADER.COMPONENT_WEB
        },
        method: METHOD.PATCH,
        body: JSON.stringify(updatedRight)
    }).then(computeBasicResponse);
};
export const applyRight = ({ workspaceId, documentId, versionId, rightId, authorizationHeader, baseUrl }) => {
    authorizationHeader = throwIfAuthorizationHeaderIsFalsy({
        authorizationHeader
    });
    return fetch(`${baseUrl}/workspaces/${workspaceId}/documents/${documentId}/versions/${versionId}/rights`, {
        headers: {
            authorization: authorizationHeader,
            accept: HEADER.APPLICATION_JSON,
            'content-type': HEADER.APPLICATION_JSON,
            'sgm-docs-component': HEADER.COMPONENT_WEB
        },
        method: 'PUT',
        body: JSON.stringify({ rightId: rightId })
    }).then(computeBasicResponse);
};
export const updateDocumentMetadata = ({ workspaceId, documentId, confidentialityLevel, destroyDate, contentsOutsideSGNetwork, metadataChanges, authorizationHeader, baseUrl }) => {
    authorizationHeader = throwIfAuthorizationHeaderIsFalsy({
        authorizationHeader
    });
    const queryParameters = createQueryParameters({ confidentialityLevel, destroyDate, contentsOutsideSGNetwork });
    return fetch(`${baseUrl}/workspaces/${workspaceId}/documents/${documentId}/versions/metadata${queryParameters ? '?' + queryParameters : ''}`, {
        headers: {
            authorization: authorizationHeader,
            accept: HEADER.APPLICATION_JSON,
            'content-type': HEADER.APPLICATION_JSON,
            'sgm-docs-component': HEADER.COMPONENT_WEB
        },
        method: METHOD.POST,
        body: JSON.stringify(metadataChanges)
    }).then(computeBasicResponse);
};
export const retrieveContentSize = ({ workspaceId, documentId, versionId = 'last', mimeType, authorizationHeader, baseUrl }) => {
    authorizationHeader = throwIfAuthorizationHeaderIsFalsy({
        authorizationHeader
    });
    return fetch(`${baseUrl}/workspaces/${workspaceId}/documents/${documentId}/versions/${versionId}/contents/do-view`, {
        headers: {
            authorization: authorizationHeader,
            'Content-Type': HEADER.APPLICATION_JSON,
            accept: mimeType,
            'sgm-docs-component': HEADER.COMPONENT_WEB
        },
        method: 'HEAD'
    }).then((r) => parseInt(r.headers.get(`Content-Length`) || '0', 10));
};
export const downloadContent = ({ workspaceId, documentId, versionId = 'last', mimeType, authorizationHeader, baseUrl, range, component = HEADER.COMPONENT_WEB }) => {
    authorizationHeader = throwIfAuthorizationHeaderIsFalsy({
        authorizationHeader
    });
    const headers = {
        authorization: authorizationHeader,
        'Content-Type': HEADER.APPLICATION_JSON,
        accept: mimeType,
        'sgm-docs-component': component
    };
    if (range) {
        headers['range'] = range;
    }
    return fetch(`${baseUrl}/workspaces/${workspaceId}/documents/${documentId}/versions/${versionId}/contents/do-view`, {
        headers
    });
};
export const uploadContent = ({ workspaceId, documentId, authorizationHeader, file, fileName, contentLength, contentRange, contentType, baseUrl, rightId, component = HEADER.COMPONENT_WEB }) => {
    authorizationHeader = throwIfAuthorizationHeaderIsFalsy({
        authorizationHeader
    });
    let uploadUrl = `${baseUrl}/upload/workspaces/${workspaceId}/documents/${documentId}/contents`;
    if (rightId) {
        uploadUrl += `?rightId=${rightId}`;
    }
    contentType = contentType || (file instanceof File ? file.type : undefined);
    fileName = fileName || (file instanceof File ? file.name : undefined);
    contentLength = contentLength || (file instanceof File ? file.size : undefined);
    if (!contentType) {
        throw new Error(`The content type is mandatory to provide when uploading a content.`);
    }
    if (!fileName && contentRange !== 'reset') {
        throw new Error(`The file name is mandatory to provide when uploading a content.`);
    }
    if (!contentLength && contentRange !== 'reset') {
        throw new Error(`The content length is mandatory to provide when uploading a content.`);
    }
    const headers = {
        authorization: authorizationHeader,
        'Content-Type': contentType,
        'Content-Length': `${contentLength}`,
        accept: HEADER.APPLICATION_JSON,
        'sgm-docs-component': component
    };
    if (fileName) {
        headers['Content-Disposition'] = `binary;filename*=UTF-8''${fixedEncodeURIComponent(fileName)}`;
    }
    if (contentRange) {
        headers['Content-Range'] = contentRange;
    }
    return fetch(uploadUrl, {
        method: METHOD.POST,
        headers: headers,
        body: file
    }).then(computeAsyncBasicResponse);
};
export const deleteContent = ({ workspaceId, documentId, mimeType, authorizationHeader, baseUrl }) => {
    authorizationHeader = throwIfAuthorizationHeaderIsFalsy({
        authorizationHeader
    });
    return fetch(`${baseUrl}/workspaces/${workspaceId}/documents/${documentId}/contents?mimeType=${mimeType}`, {
        method: METHOD.DELETE,
        headers: {
            authorization: authorizationHeader,
            'Content-Type': HEADER.APPLICATION_JSON,
            accept: HEADER.APPLICATION_JSON,
            'sgm-docs-component': HEADER.COMPONENT_WEB
        }
    }).then(computeBasicResponse);
};
export const getDocumentContentOCR = ({ workspaceId, documentId, versionId, authorizationHeader, mimeType, resultFormat, baseUrl }) => {
    authorizationHeader = throwIfAuthorizationHeaderIsFalsy({
        authorizationHeader
    });
    const headers = {
        authorization: authorizationHeader,
        'Accept': resultFormat,
        'sgm-docs-component': HEADER.COMPONENT_WEB
    };
    return fetch(`${baseUrl}/workspaces/${workspaceId}/documents/${documentId}/versions/${versionId}/contents/ocr/results?mimeType=${mimeType}`, {
        method: 'GET',
        headers: headers
    }).then(computeBasicTextResponse);
};
export const doContentOCR = ({ workspaceId, documentId, versionId, contentType, authorizationHeader, baseUrl, abortSignal }) => {
    authorizationHeader = throwIfAuthorizationHeaderIsFalsy({
        authorizationHeader
    });
    const headers = {
        authorization: authorizationHeader,
        'Content-Type': HEADER.APPLICATION_JSON,
        'sgm-docs-component': HEADER.COMPONENT_WEB
    };
    return fetch(`${baseUrl}/workspaces/${workspaceId}/documents/${documentId}/versions/${versionId}/contents/do-ocr`, {
        method: METHOD.POST,
        headers: headers,
        signal: abortSignal,
        body: JSON.stringify({ contentType: contentType })
    }).then(computeBasicResponseWithoutBody);
};
export const deleteContentOCR = ({ workspaceId, documentId, versionId, mimeType, authorizationHeader, baseUrl }) => {
    authorizationHeader = throwIfAuthorizationHeaderIsFalsy({
        authorizationHeader
    });
    const headers = {
        authorization: authorizationHeader,
        'sgm-docs-component': HEADER.COMPONENT_WEB
    };
    return fetch(`${baseUrl}/workspaces/${workspaceId}/documents/${documentId}/versions/${versionId}/contents/ocr/results?mimeType=${mimeType}`, {
        method: METHOD.DELETE,
        headers: headers
    }).then(computeDeleteResponse);
};
export const deleteModel = ({ workspaceId, modelId, authorizationHeader, baseUrl }) => {
    authorizationHeader = throwIfAuthorizationHeaderIsFalsy({
        authorizationHeader
    });
    return fetch(`${baseUrl}/workspaces/${workspaceId}/models/${modelId}`, {
        method: METHOD.DELETE,
        headers: {
            authorization: authorizationHeader,
            'Content-Type': HEADER.APPLICATION_JSON,
            accept: HEADER.APPLICATION_JSON,
            'sgm-docs-component': HEADER.COMPONENT_WEB
        }
    }).then(computeDeleteResponse);
};
export const getModel = ({ workspaceId, modelId, authorizationHeader, baseUrl, component = HEADER.COMPONENT_WEB }) => {
    authorizationHeader = throwIfAuthorizationHeaderIsFalsy({
        authorizationHeader
    });
    return fetch(`${baseUrl}/workspaces/${workspaceId}/models/${modelId}`, {
        headers: {
            authorization: authorizationHeader,
            'Content-Type': HEADER.APPLICATION_JSON,
            accept: HEADER.APPLICATION_JSON,
            'sgm-docs-component': component
        }
    }).then(computeBasicResponse);
};
export const getModelByVersion = ({ workspaceId, modelId, modelVersionId = 'last', authorizationHeader, baseUrl }) => {
    authorizationHeader = throwIfAuthorizationHeaderIsFalsy({
        authorizationHeader
    });
    return fetch(`${baseUrl}/workspaces/${workspaceId}/models/${modelId}/versions/${modelVersionId}`, {
        headers: {
            authorization: authorizationHeader,
            'Content-Type': HEADER.APPLICATION_JSON,
            accept: HEADER.APPLICATION_JSON,
            'sgm-docs-component': HEADER.COMPONENT_WEB
        }
    }).then(computeBasicResponse);
};
export const getModels = ({ workspaceId, authorizationHeader, query = '', page = '1', size = '20', baseUrl }) => {
    authorizationHeader = throwIfAuthorizationHeaderIsFalsy({
        authorizationHeader
    });
    return fetch(`${baseUrl}/workspaces/${workspaceId}/models?q=${query}&page=${page}&size=${size}`, {
        headers: {
            authorization: authorizationHeader,
            'Content-Type': HEADER.APPLICATION_JSON,
            accept: HEADER.APPLICATION_JSON,
            'sgm-docs-component': HEADER.COMPONENT_WEB
        }
    }).then(computeBasicResponse);
};
export const searchDocuments = ({ query, scope, authorizationHeader, page = '1', size = '20', sort, baseUrl }) => {
    authorizationHeader = throwIfAuthorizationHeaderIsFalsy({
        authorizationHeader
    });
    const urlSearchParams = sort
        ? new URLSearchParams({ page: page, size: size, scope: scope, sort: sort })
        : new URLSearchParams({ page: page, size: size, scope: scope });
    return fetch(`${baseUrl}/search?` + urlSearchParams, {
        method: METHOD.POST,
        headers: {
            authorization: authorizationHeader,
            'Content-Type': HEADER.APPLICATION_JSON,
            accept: HEADER.APPLICATION_JSON,
            'sgm-docs-component': HEADER.COMPONENT_WEB
        },
        body: JSON.stringify({
            query: query
        })
    }).then(computeBasicResponse);
};
export const getSearchableFields = ({ query = '', workspaceIds = [], modelIds = [], page = '1', size = '20', authorizationHeader, baseUrl, component = HEADER.COMPONENT_WEB }) => {
    authorizationHeader = throwIfAuthorizationHeaderIsFalsy({
        authorizationHeader
    });
    return fetch(`${baseUrl}/searchable-fields?q=${query}&workspaceIds=${workspaceIds.join(',')}&modelIds=${modelIds.join(',')}&page=${page}&size=${size}`, {
        method: 'GET',
        headers: {
            authorization: authorizationHeader,
            'Content-Type': HEADER.APPLICATION_JSON,
            accept: HEADER.APPLICATION_JSON,
            'sgm-docs-component': component
        }
    }).then(computeBasicResponse);
};
export const deleteWorkspace = ({ workspaceId, authorizationHeader, baseUrl }) => {
    authorizationHeader = throwIfAuthorizationHeaderIsFalsy({
        authorizationHeader
    });
    return fetch(`${baseUrl}/workspaces/${workspaceId}`, {
        method: METHOD.DELETE,
        headers: {
            authorization: authorizationHeader,
            'Content-Type': HEADER.APPLICATION_JSON,
            accept: HEADER.APPLICATION_JSON,
            'sgm-docs-component': HEADER.COMPONENT_WEB
        }
    }).then(computeDeleteResponse);
};
export const copyWorkspace = ({ workspaceId, workspaceRequest, authorizationHeader, baseUrl }) => {
    authorizationHeader = throwIfAuthorizationHeaderIsFalsy({
        authorizationHeader
    });
    return fetch(`${baseUrl}/workspaces/${workspaceId}/copies`, {
        method: METHOD.POST,
        headers: {
            authorization: authorizationHeader,
            'Content-Type': HEADER.APPLICATION_JSON,
            accept: HEADER.APPLICATION_JSON,
            'sgm-docs-component': HEADER.COMPONENT_WEB
        },
        body: JSON.stringify(workspaceRequest)
    }).then(computeBasicResponse);
};
export const getWorkspace = ({ workspaceId, authorizationHeader, baseUrl }) => {
    authorizationHeader = throwIfAuthorizationHeaderIsFalsy({
        authorizationHeader
    });
    return fetch(`${baseUrl}/workspaces/${workspaceId}`, {
        headers: {
            authorization: authorizationHeader,
            'Content-Type': HEADER.APPLICATION_JSON,
            accept: HEADER.APPLICATION_JSON,
            'sgm-docs-component': HEADER.COMPONENT_WEB
        }
    }).then(computeBasicResponse);
};
export const getWorkspaces = ({ query = undefined, involved = undefined, owned = undefined, visibilityLevels = undefined, authorizationHeader, page = '1', size = '20', baseUrl }) => {
    authorizationHeader = throwIfAuthorizationHeaderIsFalsy({
        authorizationHeader
    });
    const whereQuery = query !== undefined ? `&q=${query}` : '';
    const whereInvolved = involved !== undefined ? `&involved=${involved}` : '';
    const whereOwned = owned !== undefined ? `&owned=${owned}` : '';
    const whereVisibilityLevel = visibilityLevels && visibilityLevels.length > 0
        ? visibilityLevels.map((level) => `&visibilityLevels=${level}`).join('')
        : '';
    return fetch(`${baseUrl}/workspaces?page=${page}&size=${size}${whereQuery}${whereInvolved}${whereOwned}${whereVisibilityLevel}`, {
        method: 'GET',
        headers: {
            authorization: authorizationHeader,
            'Content-Type': HEADER.APPLICATION_JSON,
            accept: HEADER.APPLICATION_JSON,
            'sgm-docs-component': HEADER.COMPONENT_WEB
        }
    }).then(computeBasicResponse);
};
export const createRight = ({ right, authorizationHeader, baseUrl }) => {
    authorizationHeader = throwIfAuthorizationHeaderIsFalsy({
        authorizationHeader
    });
    return fetch(`${baseUrl}/rights`, {
        method: METHOD.POST,
        headers: {
            authorization: authorizationHeader,
            'Content-Type': HEADER.APPLICATION_JSON,
            accept: HEADER.APPLICATION_JSON,
            'sgm-docs-component': HEADER.COMPONENT_WEB
        },
        body: JSON.stringify(right)
    }).then(computeAsyncBasicResponse);
};
export const createWorkspace = ({ workspaceRequest, authorizationHeader, baseUrl }) => {
    authorizationHeader = throwIfAuthorizationHeaderIsFalsy({
        authorizationHeader
    });
    return fetch(`${baseUrl}/workspaces`, {
        method: METHOD.POST,
        headers: {
            authorization: authorizationHeader,
            'Content-Type': HEADER.APPLICATION_JSON,
            accept: HEADER.APPLICATION_JSON,
            'sgm-docs-component': HEADER.COMPONENT_WEB
        },
        body: JSON.stringify(workspaceRequest)
    }).then(computeAsyncBasicResponse);
};
export const createModel = ({ workspaceId, modelRequest, authorizationHeader, baseUrl }) => {
    authorizationHeader = throwIfAuthorizationHeaderIsFalsy({
        authorizationHeader
    });
    return fetch(`${baseUrl}/workspaces/${workspaceId}/models`, {
        method: METHOD.POST,
        headers: {
            authorization: authorizationHeader,
            'Content-Type': HEADER.APPLICATION_JSON,
            accept: HEADER.APPLICATION_JSON,
            'sgm-docs-component': HEADER.COMPONENT_WEB
        },
        body: JSON.stringify(modelRequest)
    }).then(computeAsyncBasicResponse);
};
export const updateOrVersionedModel = ({ workspaceId, modelId, updateModelRequest, authorizationHeader, baseUrl }) => {
    authorizationHeader = throwIfAuthorizationHeaderIsFalsy({
        authorizationHeader
    });
    return fetch(`${baseUrl}/workspaces/${workspaceId}/models/${modelId}`, {
        method: METHOD.PATCH,
        headers: {
            authorization: authorizationHeader,
            'Content-Type': HEADER.APPLICATION_JSON,
            accept: HEADER.APPLICATION_JSON,
            'sgm-docs-component': HEADER.COMPONENT_WEB
        },
        body: JSON.stringify(updateModelRequest)
    }).then(computeAsyncBasicResponse);
};
export const updateWorkspace = ({ workspaceId, updateWorkspaceRequest, authorizationHeader, baseUrl }) => {
    authorizationHeader = throwIfAuthorizationHeaderIsFalsy({
        authorizationHeader
    });
    return fetch(`${baseUrl}/workspaces/${workspaceId}`, {
        method: METHOD.PATCH,
        headers: {
            authorization: authorizationHeader,
            'Content-Type': HEADER.APPLICATION_JSON,
            accept: HEADER.APPLICATION_JSON,
            'sgm-docs-component': HEADER.COMPONENT_WEB
        },
        body: JSON.stringify(updateWorkspaceRequest)
    }).then(computeAsyncBasicResponse);
};
export const getPermalinkAudits = ({ permalinkId, eventTypes, authorizationHeader, page = '1', size = '200', sort = '-occurredOn', baseUrl }) => {
    authorizationHeader = throwIfAuthorizationHeaderIsFalsy({
        authorizationHeader
    });
    const queryParameters = createQueryParameters({ eventTypes: eventTypes.join(), page, size });
    return fetch(`${baseUrl}/permalinks/${permalinkId}/audits?sort=${sort}${queryParameters ? '&' + queryParameters : ''}`, {
        headers: {
            authorization: authorizationHeader,
            'Content-Type': HEADER.APPLICATION_JSON,
            accept: HEADER.APPLICATION_JSON,
            'sgm-docs-component': HEADER.COMPONENT_WEB
        }
    }).then(computeBasicResponse);
};
export const getSessionPermalinks = ({ authorizationHeader, page = '1', size = '50', baseUrl }) => {
    authorizationHeader = throwIfAuthorizationHeaderIsFalsy({
        authorizationHeader
    });
    return fetch(`${baseUrl}/permalinks?page=${page}&size=${size}`, {
        headers: {
            authorization: authorizationHeader,
            'Content-Type': HEADER.APPLICATION_JSON,
            accept: HEADER.APPLICATION_JSON,
            'sgm-docs-component': HEADER.COMPONENT_WEB
        }
    }).then((r) => __awaiter(void 0, void 0, void 0, function* () {
        if (r.ok) {
            return r.json();
        }
        else {
            throw yield r.json();
        }
    }));
};
export const getPermalinkProperties = ({ permalinkId, authorizationHeader, baseUrl }) => {
    authorizationHeader = throwIfAuthorizationHeaderIsFalsy({
        authorizationHeader
    });
    return fetch(`${baseUrl}/permalinks/${permalinkId}/properties`, {
        headers: {
            authorization: authorizationHeader,
            'Content-Type': HEADER.APPLICATION_JSON,
            accept: HEADER.APPLICATION_JSON,
            'sgm-docs-component': HEADER.COMPONENT_WEB
        }
    }).then(computeBasicResponse);
};
export const getPermalink = ({ permalinkId, range, authorizationHeader, baseUrl, component = HEADER.COMPONENT_WEB }) => {
    const headers = {
        'Content-Type': HEADER.APPLICATION_JSON,
        accept: '*/*',
        'sgm-docs-component': component
    };
    if (authorizationHeader) {
        headers['authorization'] = authorizationHeader;
    }
    if (range) {
        headers['range'] = range;
    }
    return fetch(`${baseUrl}/permalinks/${permalinkId}`, {
        headers
    });
};
export const getPermalinks = ({ workspaceId, documentId, versionId, page = '1', size = '20', sort, mimeType, baseUrl, authorizationHeader }) => {
    authorizationHeader = throwIfAuthorizationHeaderIsFalsy({
        authorizationHeader
    });
    const urlSearchParams = new URLSearchParams({ page: page, size: size });
    sort !== undefined && urlSearchParams.set('sort', sort);
    mimeType !== undefined && urlSearchParams.set('mimeType', mimeType);
    return fetch(`${baseUrl}/workspaces/${workspaceId}/documents/${documentId}/versions/${versionId}/permalinks?$` + urlSearchParams, {
        headers: {
            authorization: authorizationHeader,
            'Content-Type': HEADER.APPLICATION_JSON,
            accept: HEADER.APPLICATION_JSON,
            'sgm-docs-component': HEADER.COMPONENT_WEB
        }
    }).then(computeBasicResponse);
};
export const createPermalink = ({ permalinkRequest, authorizationHeader, baseUrl }) => {
    authorizationHeader = throwIfAuthorizationHeaderIsFalsy({
        authorizationHeader
    });
    return fetch(`${baseUrl}/permalinks`, {
        method: METHOD.POST,
        headers: {
            authorization: authorizationHeader,
            'Content-Type': HEADER.APPLICATION_JSON,
            accept: HEADER.APPLICATION_JSON,
            'sgm-docs-component': HEADER.COMPONENT_WEB
        },
        body: JSON.stringify(permalinkRequest)
    }).then(computeBasicResponse);
};
export const updatePermalink = ({ permalinkId, expirationDateTime, sharedWith, maxUses, authorizationHeader, baseUrl }) => {
    authorizationHeader = throwIfAuthorizationHeaderIsFalsy({
        authorizationHeader
    });
    const body = {};
    if (expirationDateTime) {
        body.expirationDateTime = expirationDateTime;
    }
    if (sharedWith) {
        body.sharedWith = sharedWith;
    }
    if (maxUses !== undefined) {
        body.maxUses = maxUses;
    }
    return fetch(`${baseUrl}/permalinks/${permalinkId}`, {
        method: METHOD.PATCH,
        headers: {
            authorization: authorizationHeader,
            'Content-Type': HEADER.APPLICATION_JSON,
            accept: HEADER.APPLICATION_JSON,
            'sgm-docs-component': HEADER.COMPONENT_WEB
        },
        body: JSON.stringify(body)
    }).then(computeBasicResponse);
};
export const notifyPermalink = ({ permalinkId, permalinkNotification, authorizationHeader, baseUrl }) => {
    authorizationHeader = throwIfAuthorizationHeaderIsFalsy({
        authorizationHeader
    });
    return fetch(`${baseUrl}/permalinks/${permalinkId}/notify`, {
        method: METHOD.POST,
        headers: {
            authorization: authorizationHeader,
            'Content-Type': HEADER.APPLICATION_JSON,
            accept: HEADER.APPLICATION_JSON,
            'sgm-docs-component': HEADER.COMPONENT_WEB
        },
        body: JSON.stringify(permalinkNotification)
    }).then(computeBasicResponse);
};
export const revokePermalink = ({ permalinkId, authorizationHeader, baseUrl }) => {
    authorizationHeader = throwIfAuthorizationHeaderIsFalsy({
        authorizationHeader
    });
    return fetch(`${baseUrl}/permalinks/${permalinkId}`, {
        method: METHOD.DELETE,
        headers: {
            authorization: authorizationHeader,
            'Content-Type': HEADER.APPLICATION_JSON,
            accept: HEADER.APPLICATION_JSON,
            'sgm-docs-component': HEADER.COMPONENT_WEB
        }
    }).then(computeDeleteResponse);
};
export const sendNotification = ({ notification, authorizationHeader, baseUrl }) => {
    authorizationHeader = throwIfAuthorizationHeaderIsFalsy({
        authorizationHeader
    });
    return fetch(`${baseUrl}/notifications`, {
        method: METHOD.POST,
        headers: {
            authorization: authorizationHeader,
            'Content-Type': HEADER.APPLICATION_JSON,
            accept: HEADER.APPLICATION_JSON,
            'sgm-docs-component': HEADER.COMPONENT_WEB
        },
        body: JSON.stringify(notification)
    }).then(computeAsyncBasicResponse);
};
export const subscribe = ({ subscription, authorizationHeader, baseUrl }) => {
    authorizationHeader = throwIfAuthorizationHeaderIsFalsy({
        authorizationHeader
    });
    return fetch(`${baseUrl}/subscriptions`, {
        method: METHOD.POST,
        headers: {
            authorization: authorizationHeader,
            'Content-Type': HEADER.APPLICATION_JSON,
            accept: HEADER.APPLICATION_JSON,
            'sgm-docs-component': HEADER.COMPONENT_WEB
        },
        body: JSON.stringify(subscription)
    }).then(computeBasicResponse);
};
export const updateSubscription = ({ subscriptionId, subscription, authorizationHeader, baseUrl }) => {
    authorizationHeader = throwIfAuthorizationHeaderIsFalsy({
        authorizationHeader
    });
    return fetch(`${baseUrl}/subscriptions/${subscriptionId}`, {
        method: METHOD.PATCH,
        headers: {
            authorization: authorizationHeader,
            'Content-Type': HEADER.APPLICATION_JSON,
            accept: HEADER.APPLICATION_JSON,
            'sgm-docs-component': HEADER.COMPONENT_WEB
        },
        body: JSON.stringify(subscription)
    }).then(computeBasicResponse);
};
export const getSubscriptions = ({ ids = '', types = '', page = '1', size = '20', authorizationHeader, baseUrl }) => {
    authorizationHeader = throwIfAuthorizationHeaderIsFalsy({
        authorizationHeader
    });
    const urlSearchParams = new URLSearchParams({ page: page, size: size, ids: ids, types: types });
    return fetch(`${baseUrl}/subscriptions?` + urlSearchParams, {
        headers: {
            authorization: authorizationHeader,
            'Content-Type': HEADER.APPLICATION_JSON,
            accept: HEADER.APPLICATION_JSON,
            'sgm-docs-component': HEADER.COMPONENT_WEB
        }
    }).then(computeBasicResponse);
};
export const unsubscribe = ({ subscriptionId, authorizationHeader, baseUrl }) => {
    authorizationHeader = throwIfAuthorizationHeaderIsFalsy({
        authorizationHeader
    });
    return fetch(`${baseUrl}/subscriptions/${subscriptionId}`, {
        method: METHOD.DELETE,
        headers: {
            authorization: authorizationHeader,
            'Content-Type': HEADER.APPLICATION_JSON,
            accept: HEADER.APPLICATION_JSON,
            'sgm-docs-component': HEADER.COMPONENT_WEB
        }
    }).then(computeDeleteResponse);
};
